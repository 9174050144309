import React, { useState, useEffect } from "react"

import algoliasearch from "algoliasearch/lite"
import {
  InstantSearch,
  Configure,
  // SearchBox,
  connectHits,
  // connectSearchBox,
  connectAutoComplete,
  connectStateResults,
} from "react-instantsearch-dom"
import qs from "qs"

import SEO from "../components/SEO"
import Heading from "../components/Heading"
import ProductTile from "../components/ProductTile"

import styles from "./search.module.scss"
import collectionStyles from "../templates/collection.module.scss"
import filterStyles from "../components/filters.module.scss"
import { Link } from "gatsby"
import Image from "../components/Image"


const searchClient = algoliasearch(
  "WN0K2FPQPE",
  "8e5ab94e5bb026fd40906a5712e6d546"
)


const CustomAutocomplete = connectAutoComplete(
  ({ hits, currentRefinement, refine, searchState }) => {

    return(
      <header className={styles.header}>
        <Heading
          tag="h1"
          level="0"
          className={styles.heading}
        >
          {hits.length > 0
            ? `Results for "${searchState.query}"`
            : `No results for "${searchState.query}"`
          }
        </Heading>
        <div className={filterStyles.filters}>
          <p className={filterStyles.itemCount}>{hits.length} item{hits.length !== 1 ? "s" : ""}</p>
        </div>
      </header>
    )
  }
)

// const CustomSearchBox = connectSearchBox(
//   ({ currentRefinement, refine, searchState }) => {
//
//     return(
//       <header className={collectionStyles.header}>
//         <Heading
//           tag="h1"
//           level="0"
//           className={collectionStyles.heading}
//         >
//           {searchState.query}
//         </Heading>
//       </header>
//     )
//   }
// )

// const SearchResults = connectStateResults(
//   ({ hits, searchState }) => {
//
//     return(
//       <>
//         {searchState && searchState.query
//           ? <ul className={collectionStyles.grid}>
//             {hits.map((sku, i) => {
//               sku.images = {}
//               sku.images.plp = sku.covers
//               sku.images.pdp = sku.pdp
//               sku.variants = [{price: sku.price}]
//               const colorName = sku.color.name
//               sku.color = colorName
//
//               return(
//                 // <li>{sku.title}</li>
//                 <ProductTile
//                   key={i}
//                   style={{
//                     skus: [sku],
//                     url: sku.url.replace("https://www.tamaramellon.com","").split("?")[0],
//                     title: sku.title,
//                   }}
//                 />
//               )
//             })}
//           </ul>
//           : <div>No query</div>
//         }
//       </>
//     )
//   }
// )

const NoSearchResults = () => {

  const handleLivechat = () => {
    document.querySelector(".LPMcontainer").click()
  }

  return (
    <div className={styles.noResults}>
      <h3 className={styles.heading}>We couldn't find what you're looking for.</h3>
      <h4 className={styles.subheading}>Let us help you.</h4>
      <p>Call us at <a href="tel:8664195500">(866) 419-5500</a></p>
      <p>
        <button onClick={handleLivechat}>
          Chat now
        </button>
      </p>
      <Heading
        tag="h2"
        level="2"
        className={`${styles.heading} ${styles.categoryHeading}`}
      >
        Shop by Category
      </Heading>
      <ul className={styles.categoryGrid}>
        {/*<li>*/}
        {/*  <Link*/}
        {/*    to="/collections/boots?origin=search-no-results"*/}
        {/*  >*/}
        {/*    <Image*/}
        {/*      image="https://cdn.shopify.com/s/files/1/1103/4464/products/BUCKLE-UP_50_BLACK_BOOTS_PDP_3_Model.jpg?v=1659378932"*/}
        {/*      altText="Boots Image"*/}
        {/*    />*/}
        {/*    <span>Boots</span>*/}
        {/*  </Link>*/}
        {/*</li>*/}
        <li>
          <Link
            to="/collections/sandals?origin=search-no-results"
          >
            <Image
              image="https://cdn.shopify.com/s/files/1/1103/4464/products/ABSOLUTE-40-CRYSTALS_CORDA_PDP_3_MODEL.jpg?v=1669934398"
              altText="Sandals Image"
            />
            <span>Sandals</span>
          </Link>
        </li>
        <li>
          <Link
            to="/collections/pumps?origin=search-no-results"
          >
            <Image
              image="https://cdn.shopify.com/s/files/1/1103/4464/files/Pumps_879bce8d-af69-4a73-a05f-c15a9175eefe_640x.progressive.jpg?v=1644367684"
              altText="Pumps Image"
            />
            <span>Pumps</span>
          </Link>
        </li>
        <li>
          <Link
            to="/collections/flats?origin=search-no-results"
          >
            <Image
              image="https://cdn.shopify.com/s/files/1/1103/4464/products/Wander_10_BlackNappa_Flats_PDP_5_Model_11bf1a80-7bf8-4b58-834d-6d31550446e2_640x.progressive.jpg?v=1616020090"
              altText="Flats Image"
            />
            <span>Flats</span>
          </Link>
        </li>
      </ul>
    </div>
  )
}

const CustomHits = connectHits(
  ({ hits }) => (
    <ul className={collectionStyles.grid}>
      {hits.map((sku, i) => {
        sku.images = {}
        sku.images.plp = sku.covers
        sku.images.pdp = sku.pdp
        sku.variants = [{price: sku.price}]
        const colorName = (sku.color) ? sku.color.name : null
        sku.color = colorName

        return(
          // <li>{sku.title}</li>
          <ProductTile
            key={i}
            style={{
              skus: [sku],
              url: sku.url.replace("https://www.tamaramellon.com","").split("?")[0],
              title: sku.title,
            }}
            origin="search"
            index={i}
            customStyles={styles}
            collection="Search"
            clickAnalytics={{
              objectID: sku.objectID,
              position: sku.__position,
              queryID: sku.__queryID,
            }}
          />
        )
      })}
    </ul>
  )
)


const Results = connectStateResults(
  ({ searchState, searchResults, children }) =>
    searchResults && searchResults.nbHits !== 0 ? (
      children
    ) : (
      <NoSearchResults />
    )
)


const createURL = state => `?${qs.stringify(state, { filter: ["query"] })}`

// const searchStateToUrl = (location, searchState) =>
//   searchState ? `${location.pathname}${createURL(searchState)}` : ""

const urlToSearchState = ({ search }) => qs.parse(search.slice(1))


const Search = ({ location, navigate }) => {
  // const [open, toggleOpen] = useState(true)
  const [searchState, setSearchState] = useState(urlToSearchState(location))

  useEffect(() => {
    setSearchState(urlToSearchState(location))
  }, [location])

  // const onSearchStateChange = updatedSearchState => {
  //   // toggleOpen(false)
  //   // navigate(searchStateToUrl(location, updatedSearchState))
  //
  //   setSearchState(updatedSearchState)
  // }

  return (
    <>
      <SEO
        title={`Search results for ${searchState.query}`}
        description={`Search results for ${searchState.query}`}
        url={`${location.pathname}${location.search}`}
      />

      <section className={styles.search}>
        {/* <Heading */}
        {/*   tag="h1" */}
        {/*   level="0" */}
        {/*   className={styles.heading} */}
        {/* > */}
        {/*   Search */}
        {/* </Heading> */}
        <InstantSearch
          searchClient={searchClient}
          indexName="tm_headless_search"
          searchState={searchState}
          // onSearchStateChange={onSearchStateChange}
          createURL={createURL}
        >
          <Configure
            hitsPerPage={1000}
            typoTolerance={true}
            clickAnalytics={true}
          />
          {/*<CustomSearchBox*/}
          {/*  searchState={searchState}*/}
          {/*/>*/}
          <CustomAutocomplete
            searchState={searchState}
          />
          <Results>
            <CustomHits />
          </Results>
        </InstantSearch>
      </section>
    </>
  )
}

export default Search
